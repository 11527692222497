import React, { useState, useEffect } from 'react'
import {
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
  Button,
  Slider,
  Backdrop,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { Close, FilterList } from '@mui/icons-material'

import { formatPrice } from '../../helpers'
import {
  ButtonContainer,
  CheckboxContainer,
  FilterContainer,
  FilterPriceContainer,
  FiltersContent
} from './Styled'
import { useDebounce } from '../../helpers/hooks/useDebounce'

export enum QueryStateActionType {
  UPDATE,
  INIT
}

interface FiltersProps {
  categories: any
  handleChangeFilter: (checked: boolean, id: string) => void
  state: any
  dispatch: any
  maxPrice: number
  resetFilter: () => void
}

const Filters = (props: FiltersProps) => {
  const {
    categories,
    handleChangeFilter,
    state,
    dispatch,
    maxPrice,
    resetFilter
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [price, setPrice] = useState(state.price)
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const debouncedPrice = useDebounce<[number, number]>(price)

  const handleClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen && !isTablet) {
      handleClose()
    }
  }, [isOpen, isTablet])

  useEffect(() => {
    dispatch({ price: debouncedPrice })
  }, [debouncedPrice])

  return (
    <FilterContainer>
      <Button
        onClick={() => setIsOpen(true)}
        id="mobile-filter-btn"
        color="secondary"
        variant="outlined"
        startIcon={<FilterList />}
        size="large"
      >
        Filtres
      </Button>
      <Typography id="filter-title" variant="h2">
        Filtres
      </Typography>
      <FiltersContent {...{ isOpen }}>
        <div>
          <Divider id="first-divider" />
          <IconButton onClick={handleClose} id="close-icon" color="secondary">
            <Close />
          </IconButton>
          <Typography variant="h6" style={{ marginTop: '10px' }}>
            Catégories
          </Typography>
          <CheckboxContainer>
            {categories.map(category => (
              <FormControlLabel
                key={category.id}
                control={
                  <Checkbox
                    color="primary"
                    checked={state.categories.includes(category.id)}
                    onChange={e =>
                      handleChangeFilter(e.target.checked, category.id)
                    }
                    name={category.title}
                  />
                }
                label={category.title}
              />
            ))}
          </CheckboxContainer>
          <Divider />
          <Typography variant="h6" style={{ marginTop: '10px' }}>
            Prix
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={state.promotion}
                onChange={e => dispatch({ promotion: e.target.checked })}
                name="Promotion"
              />
            }
            label="Promotion"
          />
          <Slider
            value={price}
            onChange={(e, newValue) => setPrice(newValue)}
            max={maxPrice}
            aria-labelledby="range-slider"
          />
          <FilterPriceContainer>
            <Typography variant="body2">
              {formatPrice(price[0], true)}
            </Typography>
            <Typography variant="body2">
              {formatPrice(price[1], true)}
            </Typography>
          </FilterPriceContainer>
        </div>
        <ButtonContainer>
          {(state.categories.length > 0 ||
            state.promotion ||
            state.price[0] !== 0 ||
            state.price[1] !== maxPrice) && (
            <Button variant="outlined" color="secondary" onClick={resetFilter}>
              Supprimer les filtres
            </Button>
          )}
          <Button
            id="display-result-btn"
            color="primary"
            variant="contained"
            onClick={handleClose}
          >
            Afficher les résultats
          </Button>
        </ButtonContainer>
      </FiltersContent>
      <Backdrop style={{ zIndex: 3 }} open={isOpen} onClick={handleClose} />
    </FilterContainer>
  )
}

export default Filters
