import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { isEmpty } from 'lodash'
import { Typography, Pagination, Grid } from '@mui/material'
import { Home, Store } from '@mui/icons-material'

import SEO from '../components/common/seo'
import { NoResultContainer, SubContainer } from '../components/common/Styled'

import Header from '../components/common/Header'
import ProductCard from '../components/ProductCard'
import Filters from '../components/ProductsList/Filters'
import {
  FiltersContainer,
  ProductsContainer,
  ProductsListContainer,
  PaginationContainer
} from '../components/ProductsList/Styled'
import { getParams } from '../helpers/urlParams'
import { routePaths } from '../constants/routes'
import Sort from '../components/ProductsList/Sort'
import { formatEdges } from '../helpers'
import { Category, Product } from '../helpers/types'

export enum SortOptions {
  DEFAULT = 'default',
  RECENT = 'recent',
  PRICE_ASCENDING = 'priceAscending',
  PRICE_DESCENDING = 'priceDescending',
  NAME_ASCENDING = 'nameAscending',
  NAME_DESCENDING = 'nameDescending'
}

interface QueryState {
  categories: string[]
  price: number[]
  promotion: boolean
}

type QueryStateReducer = (state: any, action: Partial<QueryState>) => any

const query = graphql`
  query {
    home: strapiHome {
      referencing {
        metaTitle
      }
    }
    shop: strapiShop {
      referencing {
        metaTitle
        metaDescription
        metaImage {
          url
          alternativeText
        }
        words {
          word
        }
      }
    }
    allStrapiProduct(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          strapi_id
          title
          images {
            url
            alternativeText
          }
          price
          keywords
          sold
          promotion
          publishedAt
          categories {
            id
            title
          }
        }
      }
    }
    allStrapiCategory {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`

const initialQueryState = {
  categories: [],
  price: [0, 200],
  promotion: false
}

const Shop = () => {
  const { allStrapiProduct, allStrapiCategory, home, shop } =
    useStaticQuery(query)

  const categories: Category[] = useMemo(
    () => formatEdges(allStrapiCategory),
    []
  )
  const products: Product[] = useMemo(() => formatEdges(allStrapiProduct), [])

  const maxPrice = useMemo(
    () =>
      products
        ? Math.max(
            ...products.flatMap(product => product.promotion || product.price)
          )
        : 200,
    [products]
  )

  const [page, setPage] = useState<number>(
    getParams('page')?.page
      ? parseInt(getParams('page')?.page as string, 10)
      : 1
  )
  const [sort, setSort] = useState<SortOptions>(SortOptions.RECENT)
  const [state, dispatch] = useReducer<QueryStateReducer>(
    (state, action) => ({
      ...state,
      ...action
    }),
    initialQueryState
  )

  const limit = 9

  useEffect(() => {
    if (products) {
      dispatch({
        price: [0, maxPrice]
      })
    }
  }, [maxPrice])

  const resetFilter = () => {
    dispatch({
      ...initialQueryState,
      price: [0, maxPrice]
    })
  }

  const getFilteredData = () =>
    products?.reduce((acc, product) => {
      if (product.price >= state.price[0] && product.price <= state.price[1]) {
        if (state.promotion && !!product.promotion) {
          if (state.categories.length > 0) {
            // eslint-disable-next-line consistent-return
            state.categories.forEach(category => {
              if (product.categories.find(el => el.id === category))
                return (acc = [...acc, product])
            })
          } else {
            return (acc = [...acc, product])
          }
        } else if (
          state.categories.length > 0 &&
          !product.promotion &&
          !state.promotion
        ) {
          // eslint-disable-next-line consistent-return
          state.categories.forEach(category => {
            if (product.categories.find(el => el.id === category))
              return (acc = [...acc, product])
          })
        } else if (!state.promotion && state.categories.length === 0) {
          return (acc = [...acc, product])
        }
      }
      return acc
    }, []) || []

  const handleChangePage = (e: React.ChangeEvent<unknown>, page: number) => {
    e.stopPropagation()
    // eslint-disable-next-line no-unused-expressions
    typeof window !== 'undefined' && window.scrollTo(0, 0)
    setPage(page)
    navigate(`${routePaths.shop}?page=${page}`)
  }

  const handleChangeFilter = (checked: boolean, id: string) => {
    setPage(1)
    navigate(`${routePaths.shop}?page=1`)
    dispatch({
      categories: checked
        ? [...state.categories, id]
        : [...state.categories.filter((filterId: string) => filterId !== id)]
    })
  }

  const handleChangeSort = (value: SortOptions) => {
    setPage(1)
    navigate(`${routePaths.shop}?page=1`)
    setSort(value)
  }

  // eslint-disable-next-line consistent-return
  const sortData = (a: Product, b: Product) => {
    if (sort === SortOptions.RECENT) {
      if (a.publishedAt > b.publishedAt) return -1
      if (a.publishedAt < b.publishedAt) return 1
    } else if (sort === SortOptions.NAME_ASCENDING) {
      return a.title.localeCompare(b.title)
    } else if (sort === SortOptions.NAME_DESCENDING) {
      return b.title.localeCompare(a.title)
    } else if (sort === SortOptions.PRICE_ASCENDING) {
      if (a.price[0] < b.price[0]) return -1
      if (a.price[0] > b.price[0]) return 1
    } else if (sort === SortOptions.PRICE_DESCENDING) {
      if (b.price[0] < a.price[0]) return -1
      if (b.price[0] > a.price[0]) return 1
    }
  }

  const title = shop.referencing.metaTitle
  const description = shop.referencing.metaDescription
  const image = shop.referencing.metaImage.url
  const keywords = shop.referencing.words

  const items = [
    {
      icon: <Home />,
      label: home.referencing.metaTitle,
      route: routePaths.home
    },
    {
      icon: <Store />,
      label: title
    }
  ]

  const filteredProducts = useMemo(() => getFilteredData(), [state])
  const sortedProducts = useMemo(
    () => filteredProducts.sort(sortData),
    [filteredProducts, sortData]
  )

  return (
    <SubContainer customWidth={2000}>
      <SEO {...{ title, description, image, keywords }} />
      <Header {...{ items, title }} />
      <Grid
        container
        flexWrap="nowrap"
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            marginTop: 2
          }
        })}
      >
        <FiltersContainer>
          <Sort {...{ sort, handleChangeSort }} />
          <Filters
            {...{
              state,
              dispatch,
              resetFilter,
              handleChangeFilter,
              maxPrice,
              categories
            }}
          />
        </FiltersContainer>
        {isEmpty(filteredProducts) ? (
          <NoResultContainer>
            <Typography>Aucun resultat</Typography>
          </NoResultContainer>
        ) : (
          <ProductsContainer>
            <ProductsListContainer>
              {sortedProducts.flatMap((product: Product, index: number) => {
                const start = (page - 1) * limit
                const end = limit * page
                if (index >= start && index < end)
                  return [<ProductCard key={product.strapi_id} {...product} />]
                return []
              })}
            </ProductsListContainer>
            {filteredProducts.length > 0 && (
              <PaginationContainer>
                <Pagination
                  count={Math.ceil(filteredProducts.length / limit)}
                  onChange={handleChangePage}
                  page={page}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </PaginationContainer>
            )}
          </ProductsContainer>
        )}
      </Grid>
    </SubContainer>
  )
}
export default Shop
