import { Select, FormControl, MenuItem, Typography } from "@mui/material";
import React from "react";
import { SortOptions } from "../../pages/shop";
import { SortContainer } from "./Styled";

interface SortProps {
  sort: SortOptions;
  handleChangeSort: (value: SortOptions) => void;
}

const Sort = (props: SortProps) => {
  const { sort, handleChangeSort } = props;
  return (
    <SortContainer>
      <Typography variant="h2">Tri</Typography>

      <FormControl variant="outlined" color="secondary" fullWidth>
        <Select
          id="sort-select"
          value={sort}
          onChange={(e: any) => handleChangeSort(e.target.value)}
        >
          <MenuItem value={SortOptions.RECENT}>Plus récent</MenuItem>
          <MenuItem value={SortOptions.PRICE_ASCENDING}>
            Prix (bas à élevé)
          </MenuItem>
          <MenuItem value={SortOptions.PRICE_DESCENDING}>
            Prix (élevé à bas)
          </MenuItem>
          <MenuItem value={SortOptions.NAME_ASCENDING}>Nom (A-Z)</MenuItem>
          <MenuItem value={SortOptions.NAME_DESCENDING}>Nom (Z-A)</MenuItem>
        </Select>
      </FormControl>
    </SortContainer>
  );
};

export default Sort;
